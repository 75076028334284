import { Controller } from "@hotwired/stimulus"
import { createRoot } from 'react-dom/client'
import { createElement } from 'react'
import Plan from '../components/RevenuePlan/Plan'

export default class extends Controller {
  connect() {
    const root = createRoot(this.element)
    root.render(
      createElement(Plan, {
        projectId: this.element.dataset.projectId,
        originPage: this.element.dataset.page,
        currencyCode: this.element.dataset.currencyCode
      })
    )
  }

  disconnect() {
    const root = this.element._root
    if (root) {
      root.unmount()
    }
  }
}