//inherit from Toggle and add open & close icons
import { Toggle } from "tailwindcss-stimulus-components"
import { post } from "@rails/request.js"

// openValue is inherited
// Connects to data-controller="toggle"

export default class extends Toggle {
    hasBeenClicked = false;
    static targets = ["open", "closed"];
    static values = {
        open: { type: Boolean, default: false },
        responseKind: { type: String, default: 'turbo-stream' },
        url: String
    }

    connect() {
        if (this.openValue) {
            super.animate();
            this.openTarget.classList.remove('hidden');
            this.closedTarget.classList.add('hidden');
        }
    }

    toggle(event) {
        if (this.hasUrlValue && !this.hasBeenClicked) {
            this.hasBeenClicked = true;
            post(this.urlValue, { body: '', responseKind: this.responseKindValue });
        }

        if (this.hasOpenTarget && this.hasClosedTarget) {
            if (this.openValue) {
                this.openTarget.classList.remove('hidden');
                this.closedTarget.classList.add('hidden');
            } else {
                this.openTarget.classList.add('hidden');
                this.closedTarget.classList.remove('hidden');
            }
        }

        super.toggle(event);
    }

    // New methods to externally open or close the toggle
    open() {
        if (!this.openValue) {
            this.openValue = true

            if (this.hasOpenTarget && this.hasClosedTarget) {
                this.openTarget.classList.remove('hidden')
                this.closedTarget.classList.add('hidden')
            }

            super.animate()
        }
    }

    close() {
        if (this.openValue) {
            this.openValue = false

            if (this.hasOpenTarget && this.hasClosedTarget) {
                this.openTarget.classList.add('hidden')
                this.closedTarget.classList.remove('hidden')
            }

            super.hide()
        }
    }
}