import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["list", "deliverable", "items"]
  static values = {
    resourceName: String,
    nestedResourceName: String
  }

  connect() {
    this.initializeDeliverablesSortable()
    this.initializeItemsSortable()
  }

  initializeDeliverablesSortable() {
    if (this.hasListTarget) {
      new Sortable(this.listTarget, {
        animation: 150,
        handle: ".deliverable-handle",
        group: "deliverables",
        onEnd: this.handleDeliverableSort.bind(this)
      })
    }
  }

  initializeItemsSortable() {
    this.itemsTargets.forEach(container => {
      new Sortable(container, {
        animation: 150,
        handle: ".item-handle",
        group: "items",
        onMove: (evt) => {
          // Return false if trying to drop after the footer row
          return !evt.related.classList.contains('footer');
        },
        onEnd: this.handleItemSort.bind(this)
      })
    })
  }

  handleDeliverableSort(event) {
    const deliverable = event.item
    const url = deliverable.dataset.sortableUpdateUrl
    let body = {}
    body[this.resourceNameValue] = {
      position: event.newIndex + 1
    }

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(body)
    })
  }

  handleItemSort(event) {
    const item = event.item
    const form = item.querySelector('form')
    let positionInput = false

    if (form) {
      positionInput = form.querySelector('input[name$="[position]"], input[name="position"]')
    }

    if (form && positionInput) {
      // Find position input with more flexible selector to handle different naming patterns
      positionInput.value = event.newIndex + 1
      form.requestSubmit()
    } else {
      const newDeliverableId = event.to.closest('[data-grouped-sortable-target="deliverable"]').dataset.id
      let body = {}
      body[this.nestedResourceNameValue] = {
        position: event.newIndex + 1
      }
      body[this.nestedResourceNameValue][`${this.resourceNameValue}_id`] = newDeliverableId
  
      fetch(event.item.dataset.sortableUpdateUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Response-Kind': 'turbo-stream'
        },
        body: JSON.stringify(body)
      })
    }
  }
}
