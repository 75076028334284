import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["deliverable"]

  toggle(event) {
    const deliverableId = event.currentTarget.dataset.deliverableId
    const taskRows = document.querySelectorAll(`tr[data-parent-deliverable-id="${deliverableId}"]`)
    const chevron = event.currentTarget.querySelector('.chevron-icon')

    chevron.classList.toggle('rotate-90')

    taskRows.forEach(row => {
      row.classList.toggle("hidden")
    })
  }
}
