import SlimSelect from 'slim-select';
import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
import _, { debounce } from 'lodash';

// Connects to data-controller="timesheet"
export default class extends Controller {
  static targets = [
    "taskHeading",
    "businessOverheadHeading",
    "leaveTypeHeading",
    "searchField",
    "task", // individual task row(s)
    "businessOverhead", // overhead row
    "leaveType", // leave type row
    "addTaskField",
    "taskSearch",
    "daySortToggle",
    "results",
    "result",
    "loadMoreButton",
    "taskTable",
    "timeEntry",
    "projectSearch",
    "taskSearch"
  ]
  static values = {
    timesheetId: Number,
    startDate: String,
    accountUserId: Number,
    sortedBy: String,
    sortDirection: String,
    excludedIds: Array,
    allowCompleteProjects: Boolean,
    page: { type: Number, default: 1 }
  }

  initialize() {
    this.search = debounce(this.search, 500).bind(this)
  }

  connect() {
    this.selectedIndex = -1
    this.resultsVisible = false
    this.slimselect = new SlimSelect({
      select: this.taskSearchTarget,
      placeholderText: "Select a task",
      events: {
        afterChange: (newVal) => {
          this.addSelectedTask(newVal, this.timesheetIdValue, this.startDateValue, this.accountUserIdValue)
        }
      },
      settings: {
        allowDeselect: true,
        disabled: true
      },
      data: []
    })
  }

  disconnect() {
    if (this.slimselect) {
      this.slimselect.destroy()
    }

    this.search = null;
  }

  handleKeyboard(e) {
    if (!this.resultsTarget.classList.contains('hidden')) {
      const results = this.resultsTarget.querySelectorAll('li')
      const lastIndex = results.length - 1

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault()
          this.selectedIndex = Math.min(this.selectedIndex + 1, lastIndex)
          this.highlightResult(results)
          break

        case 'ArrowUp':
          e.preventDefault()
          this.selectedIndex = Math.max(this.selectedIndex - 1, 0)
          this.highlightResult(results)
          break

        case 'Enter':
          e.preventDefault()
          if (this.selectedIndex >= 0) {
            if (e.shiftKey) {
              const selectedResult = results[this.selectedIndex]
              get(`/timesheets/add_task?task_id=${selectedResult.dataset.id}&account_user_id=${this.accountUserIdValue}&timesheet_id=${this.timesheetIdValue}&start_date=${this.startDateValue}`, {
                responseKind: "turbo-stream"
              })
              this.excludedIdsValue = this.excludedIdsValue.concat(parseInt(selectedResult.dataset.id))
              selectedResult.remove()
              // Adjust selectedIndex if we removed the last item
              if (this.selectedIndex >= results.length - 1) {
                this.selectedIndex = results.length - 1
              } else {
                this.selectedIndex = this.selectedIndex + 1
              }
              this.highlightResult(results)
            } else {
              results[this.selectedIndex].click()
            }
          }
          break
        case 'Escape':
          e.preventDefault()
          this.projectSearchTarget.value = ''
          this.resultsTarget.innerHTML = ''
          this.resultsTarget.classList.add('hidden')
          break
      }
    }
  }

  // Helper to update visual selection
  highlightResult(results) {
    results.forEach((result, index) => {
      if (index === this.selectedIndex) {
        result.classList.add('bg-blue-50')
        result.scrollIntoView({ block: 'nearest' })
      } else {
        result.classList.remove('bg-blue-50')
      }
    })
  }

  handleProjectResultClick(event) {
    if (event.shiftKey) {
      this.selectProjectResult(event, true)  // Pass true to indicate keeping results open
    } else {
      this.selectProjectResult(event, false) // Normal click behavior
    }
  }

  // Open/close the rows area of the table
  toggleRowVisibility(e) {
    const target = e.currentTarget.dataset.tableType;
    this[`${target}Targets`].forEach(t => t.classList.toggle('hidden'));

    const toggleChevron = this[`${target}HeadingTarget`].querySelector('[data-timesheet-target="toggleChevron"]')

    // Rotation for the chevron icon
    // Tasks are open by default
    if (target === "task") {
      toggleChevron.classList.toggle('rotate-[-90deg]');
      toggleChevron.classList.toggle('transform');
      toggleChevron.classList.toggle('transition-transform');
    } else {
      toggleChevron.classList.toggle('rotate-[90deg]');
      toggleChevron.classList.toggle('transform');
      toggleChevron.classList.toggle('transition-transform');
    }
  }

  focusSearch() {
    this.projectSearchTarget.focus()
  }

  focusTaskSearch() {
    this.searchFieldTarget.focus()
  }

  toggleTimeEntries(event) {
    const type = event.currentTarget.dataset.type
    const taskId = event.currentTarget.dataset.taskId
    const toggleChevron = event.currentTarget.querySelector('svg')
    const existingEntries = document.querySelectorAll(`[data-task-id="${taskId}"][data-type="${type}"].time-entry-row`)

    if (existingEntries.length > 0) {
      if (type == "timesheet_time_credit") {
        document.getElementById('times_timesheet_time_credit_0').remove()
      } else if (type == "timesheet_toil") {
        document.getElementById('times_timesheet_toil_0').remove()
      } else if (type == "business_overhead") {
        document.getElementById(`times_business_overhead_${taskId}`).remove()
      } else if (type == "leave_type") {
        document.getElementById(`times_leave_type_${taskId}`).remove()
      } else {
        document.getElementById(`times_task_${taskId}`).remove() // Remove the container for the sake of turbo_streams
        existingEntries.forEach(entry => entry.remove())
      }
      toggleChevron.classList.remove('rotate-90')
      event.preventDefault()
    } else {
      toggleChevron.classList.add('rotate-90')
    }
  }

  async search(e) {
    if (e.target.value.length < 3) {
      this.resultsTarget.innerHTML = ""
      this.resultsTarget.classList.add('hidden')
      this.selectedIndex = -1
      this.slimselect.setData([])
      this.slimselect.disable()

      return;
    }

    this.pageValue = 1
    this.resultsTarget.innerHTML = ""
    await this.fetchResults(e.target.value)
  }

  async fetchResults(searchTerm) {
    this.selectedIndex = -1
    const response = await get(`/projects/search.json?search=${searchTerm}&page=${this.pageValue}&active_only=${!this.allowCompleteProjectsValue}`);

    if (response.ok) {
      const data = await response.json;

      if (data.length > 0) {
        this.resultsTarget.classList.remove('hidden');

        data.map(result => {
          const template = this.resultTarget.cloneNode(true);
          template.content.querySelectorAll('li')[0].dataset.id = result.id;
          template.content.querySelectorAll('li')[0].dataset.name = result.name;
          template.content.querySelectorAll('p.project-name')[0].textContent = result.name;
          template.content.querySelectorAll('p.company-name')[0].textContent = result.company;

          this.resultsTarget.append(template.content);
        });
      }
    }
  }

  async loadMore(e) {
    e.preventDefault()
    this.pageValue++
    await this.fetchResults(this.fieldTarget.value)
  }

  async selectProjectResult(event, keepOpen = false) {
    const id = event.currentTarget.dataset.id
    this.projectSearchTarget.value = event.currentTarget.dataset.name

    const response = await get(`/projects/${id}/tasks.json?account_user_id=${this.accountUserIdValue}`)

    if (response.ok) {
      const taskData = await response.json

      this.slimselect.setData([
        { text: 'Select a task', value: '' },
        ...taskData
      ])

      this.slimselect.enable()
      this.slimselect.open()
    }

    if (!keepOpen) {
      this.resultsTarget.classList.add('hidden')
    }

  }

  async addSelectedTask(value, timesheetId, startDate, accountUserId) {
    if (!value || value.length == 0) return

    const taskId = value[0].value

    if (!taskId || !parseInt(value[0].value)) return

    await get(`/timesheets/add_task?task_id=${taskId}&account_user_id=${accountUserId}&timesheet_id=${timesheetId}&start_date=${startDate}`, {
      responseKind: "turbo-stream"
    })

    this.excludedIdsValue = this.excludedIdsValue.concat(parseInt(taskId))
  }
}
