import { Controller } from "@hotwired/stimulus"
import _, { debounce } from 'lodash';

// Connects to data-controller="form"
export default class extends Controller {
  initialize() {
    this.delayedSubmit = debounce(this.delayedSubmit, 600).bind(this);
  }

  connect() {
    this.form = this.element;
  }

  delayedSubmit() {
    this.submit();
  }

  submit() {
    this.form.requestSubmit();
  }

  toggleInput(event) {
    let input = event.target.nextElementSibling;

    if (event.target.dataset.enabled == "true") {
      input.value = false;
      this.submit();
    } else {
      input.value = true;
      this.submit();
    }
  }
}
