import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]

  connect() {
    // Finding the form element this controller is attached to
    this.element.addEventListener("submit", this.validateForm)
  }

  disconnect() {
    this.element.removeEventListener("submit", this.validateForm)
  }

  validateForm(event) {
    // Reset previous validation messages
    this.clearValidationMessages()
    
    let isValid = true
    
    // Check all fields with the required attribute
    this.fieldTargets.forEach(field => {
      if (field.required && !this.isFieldValid(field)) {
        isValid = false
        this.showValidationMessage(field)
      }
    })
    
    if (!isValid) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  isFieldValid(field) {
    // For SlimSelect fields, check the actual select element value
    if (field.classList.contains("ss-main")) {
      const selectElement = field.querySelector("select")
      return selectElement && selectElement.value
    }
    
    return field.value.trim() !== ""
  }

  showValidationMessage(field) {
    // Create error message element
    const errorMessage = document.createElement("div")
    errorMessage.classList.add("text-red-500", "text-sm", "mt-1", "validation-error")
    errorMessage.innerText = "This field is required"
    
    // Special handling for SlimSelect
    if (field.classList.contains("ss-main")) {
      field.classList.add("border-red-500")
      field.after(errorMessage)
      
      // If it's the first error, try to focus the SlimSelect
      const slimSelectInstance = field._ssCoreInstance
      if (slimSelectInstance) {
        slimSelectInstance.open()
      }
    } else {
      field.classList.add("border-red-500")
      field.after(errorMessage)
      
      // Focus the first invalid field
      if (!document.querySelector(".validation-error")) {
        field.focus()
      }
    }
  }

  clearValidationMessages() {
    // Remove all validation error messages
    this.element.querySelectorAll(".validation-error").forEach(el => el.remove())
    
    // Remove red border from fields
    this.fieldTargets.forEach(field => {
      field.classList.remove("border-red-500")
    })
  }
}
