import SlimSelect from 'slim-select';
import { Controller } from "@hotwired/stimulus";
import { post } from '@rails/request.js'
import _, { debounce } from 'lodash';

// Connects to data-controller="slimselect"
export default class extends Controller {
  static values = {
    options: Object,
    optionGroups: Array,
    url: String,
    placeholder: String
  };

  connect() {
    this.afterChange = debounce(this.afterChange, 200).bind(this);
    const options = this.optionsValue

    // Store reference to the original select element
    this.selectElement = this.element;

    // CRITICAL FIX: Remove required attribute from select element to prevent browser validation
    // We'll handle validation ourselves
    if (this.selectElement.hasAttribute('required')) {
      this.isRequired = true;
      // Store that this was required but remove the attribute to prevent browser validation
      this.selectElement.setAttribute('data-was-required', 'true');
      this.selectElement.removeAttribute('required');
    }

    if (this.hasOptionGroupsValue) {
      let data = [];
      data = [
        { text: this.placeholderValue || 'Select an option', value: '' },
        ...this.optionGroupsValue
      ]

      this.slimselect = new SlimSelect({
        select: this.element,
        events: {
          afterChange: this.afterChange,
          afterClose: e => this.afterClose(e, this.element)
        },
        ...options,
        data
      })
    } else {
      this.slimselect = new SlimSelect({
        select: this.element,
        ...options,
        events: {
          afterChange: this.afterChange,
          afterClose: e => this.afterClose(e, this.element)
        },
      })
    }

    // If there's an initial value already selected, ensure it's properly reflected in validation states
    if (this.isRequired && this.selectElement.value) {
      const slimSelectContainer = this.getSlimSelectContainer();
      if (slimSelectContainer) {
        slimSelectContainer.classList.remove('error-border');
        const errorElement = document.getElementById(`${this.selectElement.id}-error`);
        if (errorElement) errorElement.style.display = 'none';
      }
    }
  }

  disconnect() {
    this.slimselect.destroy();
  }

  reconnect() {
    this.slimselect.destroy();
    this.connect();
  }

  afterClose(e, element) {
    const event = new Event("slimselectChange");
    element.dispatchEvent(event);
  }

  async afterChange(items) {
    // When a selection changes, dispatch custom validation event
    if (this.isRequired) {
      const value = items.length > 0 ? items[0].value : '';
      const isValid = value !== '';

      const slimSelectContainer = this.getSlimSelectContainer();
      if (slimSelectContainer) {
        if (isValid) {
          slimSelectContainer.classList.remove('error-border');
          const errorElement = document.getElementById(`${this.selectElement.id}-error`);
          if (errorElement) errorElement.style.display = 'none';
        }
      }

      // Trigger validation event for the validator controller
      const validationEvent = new CustomEvent('slimselect:validate', {
        bubbles: true,
        detail: { isValid, field: this.selectElement }
      });
      this.selectElement.dispatchEvent(validationEvent);
    }

    if (!this.urlValue) return;

    const values = items.map(item => item.value);

    await post(this.urlValue, {
      responseKind: "turbo-stream",
      body: { values: values }
    });
  }

  getSlimSelectContainer() {
    // Find the SlimSelect container that follows the original select element
    return this.selectElement.nextElementSibling;
  }
}
