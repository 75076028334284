import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="quote-convert"
export default class extends Controller {
  static values = {
    projectName: String,
    quoteName: String,
    projectTemplateName: String
  }
  static targets = ["descriptionText"];

  changeConvertMethod(e) {
    const defaultDescriptionText = `Select a conversion method above to determine how best to convert your successful ${this.quoteNameValue} to a new ${this.projectNameValue}.`
    const projectLevelDescriptionText = `Without a budget to reference, Drum will use the '${this.projectTemplateNameValue}' ${this.projectNameValue} template that you've allocated to this ${this.quoteNameValue} to create the deliverables and tasks and allocate the total ${this.quoteNameValue} value as the ${this.projectNameValue} budget.\n\nYou can adjust this once the ${this.projectNameValue} has been created.`
    const budgetLevelDescriptionText = `The ${this.quoteNameValue} budget will be used to create the ${this.projectNameValue} deliverables and tasks.\n\nBudget for the ${this.projectNameValue} will be tracked at the task level.\n\nYou can adjust this once the ${this.projectNameValue} has been created.`

    if (e.target.value === "default") {
      this.descriptionTextTarget.innerHTML = projectLevelDescriptionText;
    } else if (e.target.value === "convert") {
      this.descriptionTextTarget.innerHTML = budgetLevelDescriptionText;
    } else {
      this.descriptionTextTarget.innerHTML = defaultDescriptionText;
    }
  }
}
