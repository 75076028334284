import { Controller } from "@hotwired/stimulus"
import Tagify from '@yaireo/tagify';
import _, { debounce } from 'lodash';

// Connects to data-controller="tagify"
export default class extends Controller {
  static values = { tags: Array, readOnly: Boolean, canEdit: Boolean };

  connect() {
    this.tagify = new Tagify(this.element, {
      whitelist: this.tagsValue,
      dropdown: {
        enabled: 0,
        maxItems: 20,
        position: "text",
      },
      enforceWhitelist: this.hasCanEditValue ? !this.canEditValue : false,
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(', ')
    });


    this.triggerChange = debounce(this.triggerChange, 200).bind(this)
    this.tagify.on('add', this.triggerChange.bind(this));
    this.tagify.on('remove', this.triggerChange.bind(this));
  }

  disconnect() {
    if (!this.tagify) return;

    this.tagify.destroy();
  }

  reconnect() {
    this.disconnect();
    this.connect();
  }

  triggerChange(e) {
    this.element.dispatchEvent(new Event('tagchange'));
  }
}
