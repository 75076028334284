// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"
import { registerControllers } from 'stimulus-vite-helpers'

// Register each controller with Stimulus
const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(application, controllers)

import { Alert, Tabs, Toggle, Popover } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
// application.register('dropdown', Dropdown)
application.register('tabs', Tabs)
//application.register('toggle', Toggle) // overridden with toggle_controller.js
application.register('popover', Popover)

import Sortable from 'stimulus-sortable'
application.register('sortable', Sortable)

// import NestedForm from 'stimulus-rails-nested-form'
// application.register('nested-form', NestedForm)
