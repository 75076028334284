import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pinIcon"]
  static values = {
    procedureId: Number,
    pinned: Boolean
  }

  toggle() {
    if (this.pinnedValue) {
      this.unpinDeliverable()
    } else {
      this.pinDeliverable()
    }
  }

  async pinDeliverable() {
    const response = await fetch('/pinned_project_deliverables', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        pinned_project_deliverable: {
          project_procedure_id: this.procedureIdValue
        }
      })
    })

    if (response.ok) {
      this.pinIconTarget.classList.remove('text-slate-300')
      this.pinIconTarget.classList.add('text-brand-500')
      this.pinnedValue = true

      // Show handle icon
      const handleIcon = this.element.querySelector('.deliverable-handle')
      handleIcon.classList.remove('hidden')
      handleIcon.classList.add('inline-block')

      // Move element to bottom of pinned section
      const pinnedSection = document.getElementById('pinned-deliverables')
      const procedureElement = this.element.closest('.procedure-row')
      pinnedSection.appendChild(procedureElement)
    }
  }

  async unpinDeliverable() {
    const response = await fetch(`/pinned_project_deliverables/${this.procedureIdValue}`, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      }
    })

    if (response.ok) {
      this.pinIconTarget.classList.remove('text-brand-500')
      this.pinIconTarget.classList.add('text-slate-300')
      this.pinnedValue = false

      // Hide handle icon
      const handleIcon = this.element.querySelector('.deliverable-handle')
      handleIcon.classList.remove('inline-block')
      handleIcon.classList.add('hidden')

      // Move element to top of unpinned section
      const procedureElement = this.element.closest('.procedure-row')
      const unpinnedSection = document.querySelector('#project_procedures')
      const pinnedSection = document.querySelector('#pinned-deliverables')
      unpinnedSection.insertBefore(procedureElement, pinnedSection.nextSibling)
    }
  }
}
