import { Controller } from "@hotwired/stimulus"
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ["menu"]
  
  connect() {
    // Initialize popper as null
    this.popper = null;
  }
  
  toggle() {
    if (this.menuTarget.classList.contains('hidden')) {
      this.show();
    } else {
      this.hide();
    }
  }
  
  show() {
    this.menuTarget.classList.remove('hidden');
    
    // Create the popper instance
    this.popper = createPopper(this.element, this.menuTarget, {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8], // x, y offset
          },
        },
        {
          name: 'preventOverflow',
          options: {
            boundary: window,
            padding: 8,
          },
        },
      ],
    });
  }
  
  hide(event) {
    if (event && this.element.contains(event.target)) {
      return;
    }
    
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
    
    this.menuTarget.classList.add('hidden');
  }
  
  disconnect() {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }
  }
}
