import { useMemo } from 'react';

interface MonthProps {
  month: {
    index: number;
    name: string;
    revenue: number;
    estimatedRevenue: number;
    revenueHeight: number;
    estimatedRevenueHeight: number;
    plannedInvoice: number;
    plannedInvoicePercent: number;
    locked: boolean;
  };
  total: number;
  currencyCode: string;
  locale: string;
  onToggleLock: (index: number) => void;
  onUpdateMonth: (data: { index: number; plannedInvoice?: number; plannedInvoicePercent?: number }) => void;
  index: number;
}

const Month = ({
  month,
  total,
  currencyCode,
  locale,
  onToggleLock,
  onUpdateMonth,
}: MonthProps) => {
  const formattedRevenue = useMemo(() => {
    return month.revenue.toLocaleString(locale, {
      style: 'currency',
      currency: currencyCode
    });
  }, [month.revenue, currencyCode]);

  const formattedEstimatedRevenue = useMemo(() => {
    return month.estimatedRevenue.toLocaleString(locale, {
      style: 'currency',
      currency: currencyCode
    });
  }, [month.estimatedRevenue, currencyCode]);

  const leftRounded = useMemo(() => {
    if (month.index % 4 === 0 || month.index === 0) {
      return "rounded-l-lg";
    }
    return "";
  }, [month.index]);

  const rightRounded = useMemo(() => {
    if (month.index % 4 === 3 || (month.index === 0 && total === 1) || month.index + 1 === total) {
      return "rounded-r-lg";
    }
    return "";
  }, [month.index, total]);

  const revenueColour = useMemo(() => {
    const revenueHeightProp = month.revenueHeight;
    if (revenueHeightProp === 100) return 'bg-green-500/20';
    if (revenueHeightProp > 0.75) return 'bg-green-500/10';
    if (revenueHeightProp > 0.5) return 'bg-yellow-500/10';
    if (revenueHeightProp > 0.25) return 'bg-orange-500/10';
    return 'bg-red-500/10';
  }, [month.revenueHeight]);

  const revenueHeight = useMemo(() => {
    const revenueHeightProp = Math.round(month.revenueHeight);
    if (revenueHeightProp <= 0) return 'h-0';
    return `h-[${revenueHeightProp}%]`;
  }, [month.revenueHeight]);

  const estimatedColour = useMemo(() => {
    const revenueHeightProp = month.estimatedRevenueHeight;
    if (revenueHeightProp === 100) return 'bg-green-500/10';
    if (revenueHeightProp > 0.75) return 'bg-green-400/10';
    if (revenueHeightProp > 0.5) return 'bg-yellow-400/10';
    if (revenueHeightProp > 0.25) return 'bg-orange-400/10';
    return 'bg-red-400/10';
  }, [month.estimatedRevenueHeight]);

  const estimatedRevenueHeight = useMemo(() => {
    const revenueHeight = month.revenueHeight;
    const estimatedRevenueHeight = month.estimatedRevenueHeight;
    let estimatedPercentage = 0;

    if (revenueHeight <= 0) {
      estimatedPercentage = estimatedRevenueHeight;
    } else {
      estimatedPercentage = (estimatedRevenueHeight / revenueHeight) * revenueHeight;
    }

    return `h-[${Math.round(estimatedPercentage)}%]`;
  }, [month.revenueHeight, month.estimatedRevenueHeight]);

  const handlePlannedInvoiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateMonth({
      index: month.index,
      plannedInvoice: parseFloat(e.target.value)
    });
  };

  const handlePlannedInvoicePercentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateMonth({
      index: month.index,
      plannedInvoicePercent: parseFloat(e.target.value)
    });
  };

  return (
    <div className="text-center">
      <div className="flex items-center justify-center space-x-2 mb-1">
        <div className="w-24">
          <div className="relative mt-2">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              value={month.plannedInvoice}
              onChange={handlePlannedInvoiceChange}
              name="price"
              className="pl-7 form-control"
              placeholder="0.00"
              disabled={!month.locked}
            />
          </div>
        </div>
        <div className="w-24">
          <div className="relative mt-2">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">%</span>
            </div>
            <input
              type="number"
              value={month.plannedInvoicePercent}
              onChange={handlePlannedInvoicePercentChange}
              name="price"
              step="1"
              className="pl-7 form-control"
              placeholder="0.00"
              disabled={!month.locked}
            />
          </div>
        </div>
        <div>
          {month.locked ? (
            <svg onClick={() => onToggleLock(month.index)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="size-3 cursor-pointer text-brand">
              <path d="M13.488 2.513a1.75 1.75 0 0 0-2.475 0L6.75 6.774a2.75 2.75 0 0 0-.596.892l-.848 2.047a.75.75 0 0 0 .98.98l2.047-.848a2.75 2.75 0 0 0 .892-.596l4.261-4.262a1.75 1.75 0 0 0 0-2.474Z" />
              <path d="M4.75 3.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25V9A.75.75 0 0 1 14 9v2.25A2.75 2.75 0 0 1 11.25 14h-6.5A2.75 2.75 0 0 1 2 11.25v-6.5A2.75 2.75 0 0 1 4.75 2H7a.75.75 0 0 1 0 1.5H4.75Z" />
            </svg>
          ) : (
            <svg onClick={() => onToggleLock(month.index)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="size-3 cursor-pointer text-orange-500">
              <path fillRule="evenodd" d="M9.58 1.077a.75.75 0 0 1 .405.82L9.165 6h4.085a.75.75 0 0 1 .567 1.241l-6.5 7.5a.75.75 0 0 1-1.302-.638L6.835 10H2.75a.75.75 0 0 1-.567-1.241l6.5-7.5a.75.75 0 0 1 .897-.182Z" clipRule="evenodd" />
            </svg>
          )}
        </div>
      </div>
      <div
        className={`bg-white border border-slate-200 mb-1 ${leftRounded} ${rightRounded}`}
      >
        <div className="relative h-16 flex justify-center items-center">
          {(month.revenue || month.estimatedRevenue) && (
            <div className="text-sm text-slate-800">
              {formattedRevenue} <span className="text-xs text-slate-500">+{formattedEstimatedRevenue}</span>
            </div>
          )}
          <div
            className={`absolute w-full bottom-0 ${revenueColour} ${revenueHeight} ${leftRounded} ${rightRounded}`}
          ></div>
          <div
            className={`absolute w-full bottom-0 ${estimatedColour} ${estimatedRevenueHeight} ${leftRounded} ${rightRounded}`}
          ></div>
        </div>
      </div>
      <p className="font-medium text-slate-800 text-sm">{month.name}</p>
    </div>
  );
};

export default Month;