import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ["name", "description", "quantity", "itemType", "quantityUnit", "billableRate"]

  connect() {
    this.handleKeyDown = this.handleKeyDown.bind(this)
    document.addEventListener("keydown", this.handleKeyDown)
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeyDown)
  }

  handleKeyDown(event) {
    if (!event.shiftKey) return

    const activeElement = document.activeElement
    const currentField = activeElement.id
    const fieldType = this.getFieldType(currentField)

    if (!fieldType) return

    switch (event.key) {
      case "ArrowDown":
      case "Down":
        event.preventDefault()
        this.navigateToField(currentField, fieldType, "next")
        break
      case "ArrowUp":
      case "Up":
        event.preventDefault()
        this.navigateToField(currentField, fieldType, "previous")
        break
      case "Enter":
        event.preventDefault()
        this.addItemBelow(activeElement, currentField, fieldType)
        break
    }
  }

  async addItemBelow(element, currentField, fieldType) {
    const currentRow = element.closest("tr")
    const referenceId = currentRow.dataset.id
    const deliverableId = currentRow.closest("[data-grouped-sortable-target='deliverable']").dataset.id

    const response = await post(`/budget_deliverables/${deliverableId}/budget_items`, {
      body: JSON.stringify({
        insert_after: referenceId
      }),
      responseKind: 'turbo-stream',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (response.ok) {
      setTimeout(() => {
        this.navigateToField(currentField, fieldType, "next")
      }, 100)
    }
  }

  getFieldType(fieldId) {
    // Extract the field type from the DOM ID (e.g., "budget_item_123_quantity" -> "quantity")
    const matches = fieldId.match(/^([^_]+)_/)
    return matches ? matches[1] : null
  }

  navigateToField(currentField, fieldType, direction) {
    const allBudgetItems = Array.from(document.querySelectorAll("[id^='budget_item_']"))
      .filter(el => el.tagName === "TR")

    const currentRow = document.getElementById(currentField).closest("tr")
    const currentIndex = allBudgetItems.indexOf(currentRow)

    let targetIndex
    if (direction === "next") {
      targetIndex = currentIndex + 1
    } else {
      targetIndex = currentIndex - 1
    }

    if (targetIndex >= 0 && targetIndex < allBudgetItems.length) {
      const targetRow = allBudgetItems[targetIndex]
      const targetField = targetRow.querySelector(`[id^="${fieldType}_"]`)

      if (targetField) {
        targetField.focus()
        targetField.select()
      }
    }
  }
}
