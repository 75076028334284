/*
 Add this controller to a new DIV that wraps what you want to scroll to.
 This element will be removed after scrolling (replaced with the inner HTML)

e.g.
 <div data-controller="scroll-to" data-scroll-to-fade-value="true" id="I will be replaced by the inner HTML">
     <div id="I am the inner HTML">
        <h1>Scroll to me</h1>
     </div>
 </div>
*/

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static values = {
        fade: { type: Boolean, default: false },
    }

    connect() {
        if (this.fadeValue) { this.element.classList.add('opacity-0', 'transition-opacity', 'duration-500'); }
        this.element.scrollIntoView();
        if (this.fadeValue) { this.fadeIn(); }

        // replace the outer div so that elements are still nested correctly for drag drop repositioning etc...
        setTimeout(() => this.element.outerHTML = this.element.innerHTML, 500 )
    }

    fadeIn () {
        this.element.classList.remove('opacity-0');
        this.element.classList.add('opacity-100');
    }
}