import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["contextField"]

  updateContext(event) {
    const selectedOption = event.target.options[event.target.selectedIndex]
    const optgroupLabel = selectedOption.parentNode.label

    if (optgroupLabel === "Project Tags") {
      this.contextFieldTarget.value = "project_tags"
    } else if (optgroupLabel === "Opportunity Tags") {
      this.contextFieldTarget.value = "opportunity_tags"
    }
  }
}
