import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar", "toggleButton", "contentHeader"]
  
  connect() {
    // Check if sidebar should start collapsed based on localStorage
    const savedState = localStorage.getItem('sidebarState')
    this.isOpen = savedState !== 'collapsed'
    
    // Apply the initial state without animation
    if (!this.isOpen) {
      this.sidebarTarget.classList.add('collapsed')
      if (this.hasContentHeaderTarget) {
        this.contentHeaderTarget.classList.remove('hidden')
      }
    } else {
      if (this.hasContentHeaderTarget) {
        this.contentHeaderTarget.classList.add('hidden')
      }
    }
  }

  toggle() {
    this.isOpen ? this.close() : this.open()
    this.isOpen = !this.isOpen
    
    // Save state to localStorage for persistence
    localStorage.setItem('sidebarState', this.isOpen ? 'open' : 'collapsed')
  }

  open() {    
    // Show sidebar by removing collapsed class
    this.sidebarTarget.classList.remove('collapsed')
    
    // Hide content header when sidebar is open
    if (this.hasContentHeaderTarget) {
      this.contentHeaderTarget.classList.add('hidden')
    }
  }

  close() {    
    // Hide sidebar by adding collapsed class
    this.sidebarTarget.classList.add('collapsed')
    
    // Show content header when sidebar is collapsed
    if (this.hasContentHeaderTarget) {
      this.contentHeaderTarget.classList.remove('hidden')
    }
  }
}
