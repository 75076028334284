import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["display", "selectContainer", "select"]

  showSelect() {
    this.displayTarget.classList.add("hidden")
    this.selectContainerTarget.classList.remove("hidden")
    // Trigger SlimSelect to open
    this.selectTarget.slim.open()
  }
}
