import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="required-field-validator"
export default class extends Controller {
  static targets = ["field", "errorMessage"];

  connect() {
    this.form = this.element;
    // Use a bound function so we can properly remove it later
    this.boundValidateForm = this.validateForm.bind(this);
    this.form.addEventListener('submit', this.boundValidateForm);

    // Listen for SlimSelect validation events
    this.boundHandleSlimSelectValidation = this.handleSlimSelectValidation.bind(this);
    this.element.addEventListener('slimselect:validate', this.boundHandleSlimSelectValidation);

    // Listen for form pre-submit events
    this.boundHandlePreSubmit = this.handlePreSubmit.bind(this);
    this.element.addEventListener('form:pre-submit', this.boundHandlePreSubmit);
  }

  disconnect() {
    this.form.removeEventListener('submit', this.boundValidateForm);
    this.element.removeEventListener('slimselect:validate', this.boundHandleSlimSelectValidation);
    this.element.removeEventListener('form:pre-submit', this.boundHandlePreSubmit);
  }

  handlePreSubmit(event) {
    // Validate all fields before form submission
    const isValid = this.validateAllFields();
    if (!isValid) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  validateAllFields() {
    let isValid = true;

    this.fieldTargets.forEach(field => {
      // Check if field is required or was required (for SlimSelect fields)
      if (field.hasAttribute('required') || field.getAttribute('data-was-required') === 'true') {
        let fieldIsValid = true;

        // Check if this is a SlimSelect field
        if (field.dataset.controller && field.dataset.controller.includes('slimselect')) {
          fieldIsValid = this.validateSlimSelect(field);
        } else {
          // Standard HTML validation for other fields
          fieldIsValid = field.checkValidity();

          if (!fieldIsValid) {
            field.reportValidity();
          }
        }

        if (!fieldIsValid) {
          isValid = false;
        }
      }
    });

    return isValid;
  }

  handleSlimSelectValidation(event) {
    const { isValid, field } = event.detail;

    if (!isValid) {
      const slimSelectContainer = field.nextElementSibling;
      if (slimSelectContainer && slimSelectContainer.classList.contains('ss-main')) {
        slimSelectContainer.classList.add('error-border');
        this.showErrorFor(field, 'This field is required');
      }
    } else {
      const slimSelectContainer = field.nextElementSibling;
      if (slimSelectContainer && slimSelectContainer.classList.contains('ss-main')) {
        slimSelectContainer.classList.remove('error-border');
        this.hideErrorFor(field);
      }
    }
  }

  validateForm(event) {
    console.log('Validating form...');
    const isValid = this.validateAllFields();

    if (!isValid) {
      event.preventDefault();
    }

    return isValid;
  }

  validateSlimSelect(field) {
    const value = field.value;
    const isValid = value !== null && value !== undefined && value !== '';

    const slimSelectContainer = field.nextElementSibling;
    if (slimSelectContainer && slimSelectContainer.classList.contains('ss-main')) {
      if (!isValid) {
        slimSelectContainer.classList.add('error-border');
        this.showErrorFor(field, 'This field is required');
      } else {
        slimSelectContainer.classList.remove('error-border');
        this.hideErrorFor(field);
      }
    }

    return isValid;
  }

  showErrorFor(field, message) {
    // Find or create error message element
    let errorElement = document.getElementById(`${field.id}-error`);
    if (!errorElement) {
      errorElement = document.createElement('p');
      errorElement.id = `${field.id}-error`;
      errorElement.classList.add('text-red-500', 'text-sm', 'mt-1');
      errorElement.dataset.requiredFieldValidatorTarget = 'errorMessage';

      // Insert after the field or after the SlimSelect container if it exists
      const slimSelectContainer = field.nextElementSibling;
      if (slimSelectContainer && slimSelectContainer.classList.contains('ss-main')) {
        slimSelectContainer.insertAdjacentElement('afterend', errorElement);
      } else {
        field.insertAdjacentElement('afterend', errorElement);
      }
    }

    errorElement.textContent = message;
    errorElement.style.display = 'block';
  }

  hideErrorFor(field) {
    const errorElement = document.getElementById(`${field.id}-error`);
    if (errorElement) {
      errorElement.style.display = 'none';
    }
  }
}
