import { Controller } from "@hotwired/stimulus"
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ["content"]

  show() {
    this.contentTarget.classList.remove('hidden');

    this.popper = createPopper(this.element, this.contentTarget)
  }

  hide() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
      this.contentTarget.classList.add('hidden');
    }
  }
}
