import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["quoteTemplate", "projectTemplate"]

  connect() {
    this.toggleTemplates();
  }

  toggleTemplates() {
    const templateType = document.querySelector('input[name="proposal_budget[template_override_type]"]:checked').value;

    if (templateType === "QuoteTemplate") {
      this.quoteTemplateTarget.style.display = "";
      this.projectTemplateTarget.style.display = "none";
    } else if (templateType === "ProjectTemplate") {
      this.quoteTemplateTarget.style.display = "none";
      this.projectTemplateTarget.style.display = "";
    }
  }

  beforeSubmit(event) {
    // Disable the hidden select field to prevent it from being submitted
    const templateType = document.querySelector('input[name="proposal_budget[template_override_type]"]:checked').value;

    if (templateType === "QuoteTemplate") {
      this.projectTemplateTarget.querySelector('select').disabled = true;
    } else if (templateType === "ProjectTemplate") {
      this.quoteTemplateTarget.querySelector('select').disabled = true;
    }
  }
}
