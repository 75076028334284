// Example usage:
// <div data-controller="tooltip" data-tooltip-content-value="Hello world"></div>
//
// Or using Tippy data attributes
// See: https://atomiks.github.io/tippyjs/v6/constructor/#attribute
//      https://atomiks.github.io/tippyjs/v6/customization/
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";

export default class extends Controller {
  static values = {
    content: String,
    position: String
  }

  connect() {
    let options = { allowHTML: true }

    if (this.hasContentValue && this.contentValue.length > 0) {
      options['content'] = this.contentValue
    } else {
      return;
    }

    if (this.hasPositionValue) {
      options['placement'] = this.positionValue
    }

    if (!options['theme']) {
      options['theme'] = 'default';
    }

    this.tippy = tippy(this.element, options);
  }

  disconnect() {
    if (this.tippy) {
      this.tippy.destroy();
    }
  }
}
