import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pinIcon", "taskName"]
  static values = {
    id: Number,
    pinned: Boolean
  }

  toggle() {
    if (this.pinnedValue) {
      this.unpinTask()
    } else {
      this.pinTask()
    }
  }

  async pinTask() {
    const response = await fetch('/pinned_tasks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        pinned_task: {
          task_id: this.idValue
        }
      })
    })

    if (response.ok) {
      this.pinIconTarget.classList.remove('text-slate-100')
      this.pinIconTarget.classList.add('text-brand')
      this.pinnedValue = true

      const handleIcon = this.taskNameTarget.classList.toggle('draggable')

      // Move element to bottom of pinned section
      const pinnedSection = document.getElementById('pinned-tasks')
      const taskElement = this.element.closest('.task-row')
      pinnedSection.appendChild(taskElement)
    }
  }

  async unpinTask() {
    const response = await fetch(`/pinned_tasks/${this.idValue}`, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      }
    })

    if (response.ok) {
      this.pinIconTarget.classList.remove('text-brand')
      this.pinIconTarget.classList.add('text-slate-100')
      this.pinnedValue = false

      // Hide handle icon
      const handleIcon = this.taskNameTarget.classList.toggle('draggable')

      // Move element to top of unpinned section
      const taskElement = this.element.closest('.task-row')
      const unpinnedSection = document.getElementById('tasks-body')
      unpinnedSection.insertBefore(taskElement, unpinnedSection.firstChild)
    }
  }
}
