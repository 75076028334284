import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["statusField"]

  select(event) {
    this.statusFieldTarget.value = event.target.dataset.status
    this.statusFieldTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }
}
