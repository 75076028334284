import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tasks"
export default class extends Controller {
  static values = { collapsed: Boolean, movable: Boolean }
  static targets = ["taskList", "taskHeader", "toggleAllButton", "toggleIcon", "processToggle", "draggableHandle", "editableProcedureName", "procedureName"]

  connect() {
    if (this.collapsedValue) {
      this.toggleAllButtonTarget.innerHTML = this.expandIcon();
    } else {
      this.toggleAllButtonTarget.innerHTML = this.collapseIcon();
    }
  }

  toggleMovable(e) {
    e.preventDefault();

    this.draggableHandleTargets.forEach((handle) => {
      if (this.movableValue) {
        handle.classList.add('hidden');
      } else {
        handle.classList.remove('hidden');
      }
    });

    this.movableValue = !this.movableValue;
  }

  toggleDeliverable(e) {
    e.preventDefault();

    const taskHeader = document.getElementById(`${e.currentTarget.dataset.id}-task-header`);
    const body = document.getElementById(`${e.currentTarget.dataset.id}-body`);

    body.classList.toggle('hidden');
    taskHeader.classList.toggle('hidden');

    if (body.classList.contains('hidden')) {
      e.currentTarget.classList.remove('rotate-[90deg]');
    } else {
      e.currentTarget.classList.add('rotate-[90deg]');
    }
  }

  toggleAll(e) {
    e.preventDefault();

    if (this.collapsedValue) {
      this.expandAll(e);
      this.collapsedValue = false;
      this.processToggleTargets.forEach((toggle) => {
        toggle.innerHTML = this.collapseIcon();
      });
    } else {
      this.collapseAll(e);
      this.collapsedValue = true;
      this.processToggleTargets.forEach((toggle) => {
        toggle.innerHTML = this.expandIcon();
      });
    }
  }

  collapseAll(e) {
    e.preventDefault();

    this.taskHeaderTargets.forEach((taskHeader) => {
      taskHeader.classList.add('hidden');
    });

    this.taskListTargets.forEach((taskList) => {
      taskList.classList.add('hidden');
    });

    this.toggleIconTargets.forEach((toggleIcon) => {
      toggleIcon.classList.remove('rotate-[90deg]')
    });

    this.toggleAllButtonTarget.innerHTML = this.expandIcon();
  }

  expandAll(e) {
    e.preventDefault();

    this.taskHeaderTargets.forEach((taskHeader) => {
      taskHeader.classList.remove('hidden');
    });

    this.taskListTargets.forEach((taskList) => {
      taskList.classList.remove('hidden');
    });

    this.toggleIconTargets.forEach((toggleIcon) => {
      toggleIcon.classList.remove('rotate-[90deg]')
      toggleIcon.classList.add('rotate-[90deg]')
    });

    this.toggleAllButtonTarget.innerHTML = this.collapseIcon();
  }

  expandIcon() {
    return `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4" title="Expand All Processes">
      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
    </svg>`;
  }

  collapseIcon() {
    return `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4" title="Collapse All Processes">
      <path stroke-linecap="round" stroke-linejoin="round" d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25" />
    </svg>
  `;
  }
}
