import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  openAll() {
    this.toggleControllers.forEach(controller => controller.open())
  }

  closeAll() {
    this.toggleControllers.forEach(controller => controller.close())
  }

  get toggleControllers() {
    // Find all toggle controllers on the page
    const toggleElements = document.querySelectorAll('[data-controller~="toggle"]')
    const controllers = []

    toggleElements.forEach(element => {
      const controller = this.application.getControllerForElementAndIdentifier(element, 'toggle')
      if (controller) {
        controllers.push(controller)
      }
    })

    return controllers
  }
}
