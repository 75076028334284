import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    startedAt: String,
    duration: Number,
    status: String,
    secondaryStartedAt: String,
    secondaryDuration: Number,
    secondaryStatus: String
  }

  static targets = ["time", "timeSecondary", "form", "formSecondary"]

  connect() {
    // Avoid duplicate timer intervals on turbo-permanent element
    if (this.element.dataset.timerInitialized === "true") return
    this.element.dataset.timerInitialized = "true"

    window.timerIntervals = window.timerIntervals || {}

    if (this.statusValue === "active") {
      let duration;
      if (this.durationValue) {
        duration = this.durationValue
      } else if (this.startedAtValue) {
        const startTime = new Date(this.startedAtValue)
        const now = new Date()
        duration = Math.floor((now - startTime) / 1000.0)
      }
      this.startTimeUpdates(duration, "primary")
    }

    if (this.secondaryStatusValue === "active") {
      let duration;
      if (this.secondaryDurationValue) {
        duration = this.secondaryDurationValue
      } else if (this.secondaryStartedAtValue) {
        const startTime = new Date(this.secondaryStartedAtValue)
        const now = new Date()
        duration = Math.floor((now - startTime) / 1000.0)
      }
      this.startTimeUpdates(duration, "secondary")
    }
  }

  disconnect() {
    // Clear both intervals on disconnect
    clearInterval(window.timerIntervals.primary)
    clearInterval(window.timerIntervals.secondary)

    window.timerIntervals.primary = null
    window.timerIntervals.secondary = null

    // Remove flag if needed for a complete disconnect
    delete this.element.dataset.timerInitialized
  }

  toggle(e) {
    e.preventDefault();
    const timerType = e.currentTarget.dataset.timerType

    if (timerType === "primary") {
      this.formTarget.classList.toggle("timer-inner__hidden")
      this.formSecondaryTarget.classList.remove("timer-inner__hidden")
      this.formSecondaryTarget.classList.add("timer-inner__hidden")
    } else {
      this.formSecondaryTarget.classList.toggle("timer-inner__hidden")
      this.formTarget.classList.remove("timer-inner__hidden")
      this.formTarget.classList.add("timer-inner__hidden")
    }
  }

  startTimeUpdates(durationSeconds, timerType = "primary") {
    const intervalKey = timerType // use "primary" or "secondary"
    const startedAtValue = timerType === "primary" ? this.startedAtValue : this.secondaryStartedAtValue
    const timeTargets = timerType === "primary" ? this.timeTargets : this.timeSecondaryTargets

    // Clear any prior interval for this timer type.
    if (window.timerIntervals[intervalKey]) {
      clearInterval(window.timerIntervals[intervalKey])
    }

    window.timerIntervals[intervalKey] = setInterval(() => {
      const startTime = new Date(startedAtValue)
      const now = new Date()
      const totalSeconds = Math.floor((now - startTime) / 1000) + durationSeconds

      timeTargets.forEach(target => {
        target.textContent = this.formatTime(totalSeconds)
      })
    }, 1000)
  }

  stopTimeUpdates(timerType = "primary") {
    const durationValue = timerType === "primary" ? this.durationValue : this.secondaryDurationValue
    const timeTargets = timerType === "primary" ? this.timeTargets : this.timeSecondaryTargets

    if (window.timerIntervals[timerType]) {
      clearInterval(window.timerIntervals[timerType])
      window.timerIntervals[timerType] = null
    }

    timeTargets.forEach(target => {
      target.textContent = this.formatTime(durationValue)
    })
  }

  formatTime(seconds) {
    if (!seconds && seconds !== 0) return "00:00:00"
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`
  }
}